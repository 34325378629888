import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import translationEN from './translations/en.json';
import translationTR from './translations/tr.json';
import translationPT_BR from './translations/pt-br.json';
import translationES_CL from './translations/es-cl.json';

export const toIsoLanguage = (code, delimiter = '_') => {
  if (!code) {
    return 'en';
  }
  if (code?.indexOf('-') > 0)
    return code?.split('-')[0]?.toLowerCase();

  const parts = code.split(delimiter);
  if (parts.length > 1) {
    return [parts[0].toLowerCase(), parts.slice(1)].join(delimiter);
  }
  return parts[0].toLowerCase();
};


const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  },
  pt_BR: {
    translation: translationPT_BR
  },
  es_CL: {
    translation: translationES_CL
  },
};


i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
    },
    resources,
  });

export default i18n;