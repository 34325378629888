import React, { useEffect } from "react";
import StateContext from "./contexts/StateContext";
import DispatchContext from "./contexts/DispatchContext";
import {
  BrowserRouter,
  Route,
  Switch as RouterSwitch,
} from "react-router-dom";
import {
  MainWrapper,
  PageContainer,
  ThemeWrapper,
} from '@flixbus/honeycomb-react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from "@azure/msal-react";
import { loginRequest, msalConfig } from "./configs/authConfig";
import { createBrowserHistory } from "history";
// Honeycomb-react helper classes
import '@flixbus/honeycomb-react/dist/css/honeycomb-helpers.css';
// imports Roboto font face and styles
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import './App.css';
import PaxWidget from "./pages/widget/PaxWidget";
import Search from "./pages/search/Search";
import AppHeader from "./components/header/AppHeader";
import { useImmerReducer } from "use-immer";
import { combinedReducer } from "./reducers/combinedReducer";
import Loader from "./components/loader/Loader";
import CustomNotification from "./helpers/CustomNotification";
import { I18nextProvider } from 'react-i18next';
function App() {

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("token")),
    token: "",
    isLoading: false,
  };

  const [state, dispatch] = useImmerReducer(combinedReducer, initialState);

  const isAuthenticated = useIsAuthenticated();

  const isIframe = window.self !== window.top;
  // const msalInstance = new PublicClientApplication(msalConfig);

  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();

  useEffect(() => {
    const effectData = async () => {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      if (accounts.length > 0) {
        return instance.acquireTokenSilent(request).then(response => localStorage.setItem("token", response.accessToken));
      }

      return instance
        .ssoSilent({
          scopes: ['User.Read'],
        })
        .catch((e) => {
          if (isIframe) {
            instance
              .acquireTokenPopup(request)
              .then((response) => {
                localStorage.setItem("token", response.accessToken);
                return response.accessToken;
              });
          } else {
            localStorage.setItem('loginRedirect', window.location.pathname);
            instance.loginRedirect(loginRequest).then(response => localStorage.setItem("token", response.accessToken));
          }
        });

    };
    effectData();
  }, [instance]);


  const history = createBrowserHistory();

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter history={history}>
          <ThemeWrapper theme="default" >
            <MainWrapper full>
                <Route exact path="/:rideId" component={PaxWidget} />
                <PageContainer Elem="main" hasFixedHeader>
                  <AuthenticatedTemplate>
                    <Loader />
                    <RouterSwitch>
                      <Route exact path="/">
                        <AppHeader />
                        <Search />
                      </Route>
                    </RouterSwitch>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                  </UnauthenticatedTemplate>
                  <CustomNotification />
                </PageContainer>
            </MainWrapper>
          </ThemeWrapper>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider >
  );
}

export default App;
