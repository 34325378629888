import { useState, useEffect } from 'react';
import { Button, Dropdown } from '@flixbus/honeycomb-react';
import { Icon, IconArrowDown } from '@flixbus/honeycomb-icons-react';
import { languagesList } from '../../locale/languagesList';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.scss';
import { useGlobalLanguage } from '../../hooks/useGlobalLanguage';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useGlobalLanguage();
  const [isActive, setActive] = useState(false);
  const activeLanguage = languagesList[language];

  const list = Object.values(languagesList).map(({ code, title, shortTitle }) => ({
    text: <span className={`lang-name lang-name--${code}`}>{title}</span>,
    href: `/${code}`,
    onClick: (e) => {
      e.preventDefault();
      setLanguage(code);
      setActive(false);
      i18n.changeLanguage(code);
    },
    InlineIcon: {
      regular: () => <span className={`flag flag--${shortTitle.toLowerCase()}`} />,
      active: () => <span className={`flag flag--${shortTitle.toLowerCase()}`} />,
    },
    activeItem: code === activeLanguage?.code,
    'data-cy': `language-${code}`,
  }));

  return (
    <div className="language-switcher-wrapper"
      onMouseOver={() => {
        setActive(true);
      }}
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      <Dropdown
        links={list}
        extraClasses="language-switcher"
        active={isActive}
        xPosition="left"
      >
        <Button className="language-switcher__button" data-cy="language-switcher" link>
          <span className={`flag flag--${activeLanguage?.shortTitle?.toLowerCase()}`} />
          {activeLanguage?.title}
          <Icon InlineIcon={IconArrowDown} style={{ fill: 'currentColor' }} />
        </Button>
      </Dropdown>
    </div>
  );
}


export default LanguageSwitcher;
