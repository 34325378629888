import {
    Connection,
    ConnectionStop,
    ImageBox,
} from "@flixbus/honeycomb-react";

import tr from '../../assets/flags/tr.svg';
import br from '../../assets/flags/br.svg';
import cl from '../../assets/flags/cl.svg';
import ind from '../../assets/flags/in.svg';

const Info = ({ data }) => {

    const findFlag = () => {
        switch (data.country[0]) {
            case 'TR':
                return tr;
            case 'BR':
                return br;
            case 'CL':
                return cl;
            case 'IN':
                return ind;
            default: {
                if (data?.country?.includes("TR"))
                    return tr;
                else
                    return null;
            }
        }
    }
    return (
        <ImageBox
            small
            img={{
                src: findFlag() || 'https://via.placeholder.com/200x200', alt: data?.country[0]
            }}
        >
            <Connection title={data?.departureTime}>
                <ConnectionStop station={data?.departure} />
                <ConnectionStop station={data?.arrival} />
            </Connection>
        </ImageBox>
    );
}

export default Info;