import Info from "../info/Info";
import DownloadButton from "../downloadButton/DownloadButton";

const BR = ({ countryData, rideId }) => {

    return (
        <>
            <Info data={countryData} />

            <DownloadButton data={countryData} />
        </>
    );
}

export default BR;