import React, { useContext, useEffect, useRef, useState } from 'react';
import {  Notification, NotificationContainer } from '@flixbus/honeycomb-react';
import StateContext from '../contexts/StateContext';

const CustomNotification = () => {
    const appState = useContext(StateContext);

    const [toasts, setToasts] = useState([]);

    const myContainer = useRef();
    const myToast = useRef();
    const removeToast = (ref) => {
        if (myContainer.current && ref && myContainer.current.contains(ref)) {
            myContainer?.current?.firstChild?.removeChild(ref);
        }
    };

    const handleCloseClick = (event) => {
        const ref = event?.target?.parentNode;
        ref && removeToast(ref);
    };

    const closeProps = { 'aria-label': 'Close', onClick: handleCloseClick };

    const toast = () => {
        let newToast = (
            <Notification toast dismissCallback={removeToast} closeProps={closeProps} appearance={appState?.notification?.appearance}>
                {appState?.notification?.message}
            </Notification>
        );

        let newToasts = [].concat(toasts);

        newToasts.push(React.cloneElement(newToast, { key: Date.now() }));
        setToasts(newToasts);
    };




    useEffect(() => {
        const effectData = async () => {
            appState?.notification?.message && toast();
        }
        effectData();
    }, [appState?.notification]);


    return (
        <div ref={myContainer}>
            <NotificationContainer position="top-right">
                {toasts}
            </NotificationContainer>
        </div>
    );
}

export default CustomNotification;