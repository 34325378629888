import React from 'react';

const PaxListMessage = () => {
    return (
        <div>
            <p>
                Dear User,
            </p>
            <p>
                Pax lists are prepared according to regulations in the specific country. <br />
                If you simply need the passenger list in the reservations tab, please try using the CSV button. <br />
                If you think there are regulatory pax list requirements in your country, reach out to Team Nebula via the Service Desk: <br />
            </p>
            <a target="_blank" href="https://flixtech.atlassian.net/servicedesk/customer/portal/18">
                Service Desk: Issues with printed passenger lists
            </a>
            {/* <p>
                Please try to include the following available information:
            </p>
            <ul>
                <li>Country of concern</li>
                <li>Do you require a custom Pax List for authorities?</li>
                <li>Reason/usage</li>
                <li>Do you have a specified format?</li>
            </ul> */}
        </div>
    );
};

export default PaxListMessage;
