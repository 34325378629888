import { login, logout } from "./loginReducer";
import { startLoader, stopLoader } from "./loadingReducer";
import { showNotification } from "./notificationReducer";
export function combinedReducer(draft, action) {
  switch (action.type) {
    case "login":
      login(draft, action);
      return;
    case "logout":
      logout(draft);
      return;
    case "startLoader":
      startLoader(draft);
      return;
    case "stopLoader":
      stopLoader(draft);
      return;
    case "showNotification":
      showNotification(draft, action);
      return;
    default:
      console.log("default", draft, action)
      return;
  }
}
