import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import axios from 'axios';
import { msalConfig, loginRequest } from '../configs/authConfig';

const BaseURL = 'https://paxlistapi-master-finance-offlinesales-public.ew1p1.k8s.flix.tech/api/';

const ApiCall = async (url, method, params) => {
    const ServisURL = BaseURL + url;
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + await getToken()
    }

    return axios({
        url: ServisURL,
        method: method.toString().toLowerCase(),
        headers: headers,
        data: params
    }).then((res) => {
        return res;
    }
    ).catch((err) => {
        if (err?.response?.status === 401) localStorage.clear();

        return err;
    });
}
export default ApiCall;



export async function getToken() {
    const msalInstance = new PublicClientApplication(msalConfig);
    const accounts = msalInstance.getAllAccounts();
    const isIframe = window.self !== window.top;

    const request = {
        ...loginRequest,
        account: accounts[0],
    };

    return new Promise((resolve, reject) => {
        msalInstance
            .acquireTokenSilent(request)
            .then((response) => {
                resolve(response.accessToken);
            })
            .catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    if (isIframe) {
                        return msalInstance.acquireTokenPopup(request).then((response) => {
                            return response.accessToken;
                        });
                    } else {
                        localStorage.setItem('loginRedirect', window.location.pathname);
                        return msalInstance.loginRedirect(request);
                    }
                }

                return e;
            })
            .catch((e) => reject(new Error(`Auth fail with error: ${e}`)));
    });
}