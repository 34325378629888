import {
    Grid,
    GridCol,
    Button,
    ButtonGroup,

} from "@flixbus/honeycomb-react";
import {
    Icon,
    IconDownload
} from "@flixbus/honeycomb-icons-react";
import { downloadPDF } from "../../helpers/download";
import { useTranslation } from "react-i18next";

const DownloadButton = ({ data, stationId = "" }) => {
    const { t } = useTranslation();
    const download = async () => {
        try {
            downloadPDF(data.rideId, stationId);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    return (
        <Grid align="center">
            <GridCol size={12}>
                <ButtonGroup>
                    <Button disabled={data?.country[0] === "TR" && !stationId} extraClasses="full-width" appearance="tertiary" onClick={download}>
                        {t('buttons.downloadPaxList')}
                        <Icon aria-hidden="true" InlineIcon={IconDownload} />
                    </Button>
                </ButtonGroup>
            </GridCol>
        </Grid>
    );
}

export default DownloadButton;