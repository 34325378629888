import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { toIsoLanguage } from '../locale/i18next.config';

export function useGlobalLanguage() {
  const { i18n } = useTranslation();

  const language = toIsoLanguage(i18n.language);

  const changeLanguage = useCallback(
    async (code) => {
      const newLng = code?.toUpperCase();
      await i18n.changeLanguage(newLng || undefined);
    },
    [i18n]
  );

  return [language, changeLanguage];
}
