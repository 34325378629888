import axios from "axios";

export function login(draft, action) {
  draft.token = action.token;
  draft.loggedIn = true;
  axios.defaults.headers.common = { Authorization: `Bearer ${action.token}` };
}
export function logout(draft) {
  draft.loggedIn = false;
  axios.defaults.headers.common = { Authorization: "" };
}
