export const languagesList = {
  en: {
    code: 'en',
    shortTitle: 'En',
    title: 'English',
    icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/gb.min.svg',
  },
  tr: {
    code: 'tr',
    shortTitle: 'Tr',
    title: 'Türkçe',
    icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/tr.min.svg',
  },
  pt_BR: {
    code: 'pt_BR',
    shortTitle: 'Pt-br',
    title: 'Portuguese (BR)',
    icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/br.min.svg',
  },
  es_CL: {
    code: 'es_CL',
    shortTitle: 'Es-cl',
    title: 'Spanish (CL)',
    icon: 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/cl.min.svg',
  },
};