import React, { useState, useEffect, useContext } from "react";
import Info from "../info/Info";
import DownloadButton from "../downloadButton/DownloadButton";
import { Select, SelectOption } from "@flixbus/honeycomb-react";
import ApiCall from "../../helpers/ApiCall";
import DispatchContext from "../../contexts/DispatchContext";
import { useTranslation } from "react-i18next";

const TR = ({ countryData, rideId }) => {
    const { t } = useTranslation();
    const appDispatch = useContext(DispatchContext);

    const [stationData, setStationData] = useState([]);
    const [stopUuid, setStopUuid] = useState();

    const getStations = async () => {
        try {
            const response = await ApiCall("GetRideStop?rideId=" + rideId + "&timezone=" + Intl.DateTimeFormat().resolvedOptions().timeZone, "Get", null);
            if (response.status === 200)
                setStationData(response.data.data);
            else
                await appDispatch({ type: "showNotification", notification: { message: response, appearance: "danger" } });

        } catch (error) {
            await appDispatch({ type: "showNotification", notification: { message: error, appearance: "danger" } });
        }
    }

    useEffect(() => {
        const effectData = async () => {
            getStations();
        }
        effectData();
    }, []);

    return (
        <>
            <Info data={countryData} />

            <Select id="station" extraClasses="flix-space-2-top flix-space-2-bottom" label={t('titles.stations')} onChange={(e) => setStopUuid(e?.target?.value)} value={stopUuid}>
                <SelectOption value={""} disabled={stopUuid} key={-1}>{t('titles.pickAStation')}</SelectOption>
                {
                    stationData.map(station =>
                        <SelectOption value={station?.stopUuid} key={station?.stopUuid}>{station.stopName}</SelectOption>
                    )
                }
            </Select>

            <DownloadButton data={countryData} stationId={stopUuid} />
        </>
    );
}

export default TR;