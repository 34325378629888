import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  Button,
  ButtonGroup,
  Calendar,
  DataTable,
  Divider,
  FormRow,
  Grid,
  GridCol,
  Heading,
  Input,
  Popup,
  PopupSection
} from "@flixbus/honeycomb-react";
import {
  Icon,
  IconDownload,
  IconCalendar,
} from "@flixbus/honeycomb-icons-react";
import ApiCall from '../../helpers/ApiCall';
import DispatchContext from '../../contexts/DispatchContext';
import StateContext from '../../contexts/StateContext';
import PaxWidget from '../widget/PaxWidget';
import { useTranslation } from 'react-i18next';
import Message from '../../components/message/Message';

const Search = () => {

  const { t } = useTranslation();

  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext);

  const [popupActive, setPopupActive] = useState(false);
  const targetRef = useRef(null);


  const [dataFrom, setDataFrom] = React.useState([]);
  const [dataTo, setDataTo] = React.useState([]);
  const [valueFrom, setValueFrom] = React.useState('');
  const [valueTo, setValueTo] = React.useState('');

  const [stationData, setStationData] = useState([]);

  const [currentRideUuid, setCurrentRideUuid] = useState();

  const [travelData, setTravelData] = useState();


  const [itemDateSelected, setItemDateSelected] = useState(new Date());

  const [showCalendar, setShowCalendar] = useState(false);

  const [itemDateValue, setItemDateValue] = useState('');

  useEffect(() => {
    const effectData = async () => {
      try {
        appDispatch({ type: "startLoader" });
        const result = await ApiCall("GetStation", "Get", null);
        setStationData(result?.data?.data);
      } catch (error) {
        console.log(error);
      }
      finally {
        appDispatch({ type: "stopLoader" });
      }
    }
    effectData();
  }, [appState?.token]);


  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      appDispatch({ type: "startLoader" });
      const response = await ApiCall("GetRides?fromId=" + (valueFrom ? valueFrom?.key : "") + "&toId=" + (valueTo ? valueTo.key : "") + "&date=" + itemDateSelected.toLocaleDateString('tr-TR'), "Get", null);
      setTravelData(response.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      appDispatch({ type: "stopLoader" });
    }

  }


  const filterAutocompleteData = (searchQuery, data) => (
    new Promise((resolve) => {
      setTimeout(() => {
        const res = data.filter(item => (
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        ));
        resolve(res);
      }, 200);
    })
  );



  const handleClear = async (e) => {
    e.preventDefault();
    setValueFrom();
    setValueTo();
    setItemDateSelected(new Date());
    setTravelData([]);
  }

  const tableDataHeaders = { cells: ['#', 'RideUuid', t('header.table.rideName'), t('header.table.action')] };
  const tableDataRows = travelData?.data?.map((item, index) => {
    return {
      cells: [
        index + 1,
        item.rideUuid,
        item.rideName,
        <ButtonGroup>
          <Button onClick={() => {
            setPopupActive(true);
            setCurrentRideUuid(item.rideUuid);
          }}>
            <Icon aria-hidden="true" InlineIcon={IconDownload} />
            PDF
          </Button>
        </ButtonGroup>,
      ]
    }
  });


  const handleOnFocus = () => {
    setDataFrom([...stationData]);
    console.log(111);
  }

  return (
    <div>
      {/* <Message /> */}
      <Grid align="center">
        <GridCol size={12} sm={3}>
          <FormRow>
            <Autocomplete
              onChange={(e) => {
              }}
              onDebounce={(e) => {
                filterAutocompleteData(e.target.value, stationData).then(
                  options => {
                    setDataFrom(options)
                  }
                )
              }}
              onSelect={(item) => {
                setDataFrom([]);
                setValueFrom(item);
              }}
              options={dataFrom}
              value={valueFrom?.title}
            >
              <AutocompleteInput
                id="autocompleteFrom"
                placeholder={t('titles.autocomplete.placeholder.tryToSearch')}
                label={t('titles.from')}
                type="search"
                onFocus={() => setDataFrom([...stationData])}
              />
              <AutocompleteOptions
                label="Places"
                optionsToDisplay={10}
                optionHasSubtitle={false}
              />
            </Autocomplete>
          </FormRow>
        </GridCol>
        <GridCol size={12} sm={3}>
          <FormRow>
            <Autocomplete
              onChange={(e) => {
              }}
              onDebounce={(e) => {
                filterAutocompleteData(e.target.value, stationData).then(
                  options => {
                    setDataTo(options)
                  }
                )
              }}
              onSelect={(item) => {
                setDataTo([]);
                setValueTo(item);
              }}
              options={dataTo}
              value={valueTo?.title}
            >
              <AutocompleteInput
                id="autocompleteTo"
                placeholder={t('titles.autocomplete.placeholder.tryToSearch')}
                label={t('titles.to')}
                type="search"
                onFocus={() => setDataTo([...stationData])}
              />
              <AutocompleteOptions
                label="Places"
                optionsToDisplay={10}
                optionHasSubtitle={false}
              />
            </Autocomplete>
          </FormRow>
        </GridCol>
        <GridCol size={12} sm={2}>
          <FormRow>
            <Input
              id="date"
              label={t('titles.date') + " DD.MM.YYYY"}
              iconLeft={<Icon aria-hidden="true" InlineIcon={IconCalendar} />}
              onFocus={() => { setShowCalendar(true) }}
              value={
                (itemDateSelected)
                  ? itemDateSelected.toLocaleDateString('tr-TR')
                  : itemDateValue
              }
              onChange={(e) => setItemDateValue(e.target.value)}
            />
            <Calendar
              extraClasses="new-item-date-calendar"
              id="calendar"
              hidden={!showCalendar}
              startDate={new Date(2023, 0, 1)}
              endDate={new Date(2032, 11, 31)}
              defaultMonth={new Date()}
              appearance={'birthday'}
              selected={itemDateSelected}
              handleSelect={(date) => {
                setTravelData([]);
                setItemDateSelected(date);
                setShowCalendar(false)
              }}
            />
          </FormRow>
        </GridCol>
        <GridCol size={12} sm={2}>
          <Button display="block" appearance="secondary" onClick={handleSearch} disabled={!itemDateSelected}>
            {t('buttons.search')}
          </Button>
        </GridCol>
        <GridCol size={12} sm={2}>
          <Button display="block" appearance="tertiary" onClick={handleClear}>
            {t('buttons.clear')}
          </Button>
        </GridCol>
      </Grid >
      <Divider extraClasses="hcr-space-1-bottom" />
      {
        travelData ?
          (travelData?.data ?
            <Heading Elem="h2" size={3}>{t('titles.travelMapListCount')} : {travelData?.data?.length}</Heading>
            :
            <Heading Elem="h3" size={3}>{travelData?.message} </Heading>
          ) : ""
      }
      {
        travelData?.data?.length > 0 &&
        <DataTable extraClasses='flix-space-8-bottom' headers={tableDataHeaders} rows={tableDataRows} />
      }

      <Popup
        id="popup-pax-widget"
        aria-labelledby="accessible-popup-title"
        aria-describedby="accessible-popup-content"
        active={popupActive}
        onOverlayClick={() => setPopupActive(false)}
        targetref={targetRef}
      >
        <PopupSection type="content" id="accessible-popup-content">
          {currentRideUuid ? <PaxWidget rideUuid={currentRideUuid} /> : ""}
        </PopupSection>
        <PopupSection type="actions">
          <Button appearance="tertiary" onClick={() => {
            setPopupActive(false);
            setCurrentRideUuid(null);
          }}>
            {t('buttons.cancel')}
          </Button>
        </PopupSection>
      </Popup>
    </div>
  );
}


export default Search;