import React, { useContext } from 'react';
import StateContext from '../../contexts/StateContext';

const Loader = () => {
  const appState = useContext(StateContext);
  return (
    <>
      {appState?.isLoading &&
        <div className="loader-container">
          <div aria-busy="true" aria-live="polite">
            <div className="flix-spinner flix-spinner--lg"></div>
          </div>
        </div>
      }
    </>
  );
};

export default Loader;
