import { saveAs } from "file-saver";
import { getToken } from "./ApiCall";

export const downloadPDF = async (rideId, stationId = "", pdfTYPE = 0) => {

    const url = `https://paxlistapi-master-finance-offlinesales-public.ew1p1.k8s.flix.tech/api/GeneratePdf?rideId=${rideId}&pdfType=${pdfTYPE}&stationId=${stationId}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + await getToken()
    };

    fetch(url, { headers })
        .then(response => response.blob())
        .then(blob => {
            saveAs(blob, rideId + '.pdf');
            // // Comment upline code and uncomment bottom 2 line code for dont save PDF only open it in browser 
            // const objectUrl = URL.createObjectURL(blob);
            // window.open(objectUrl, '_blank');
        })
        .catch(error => {
            console.error('Error:', error);
        });
};