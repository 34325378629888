import { useParams } from 'react-router-dom';
import {
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from '../../configs/authConfig';
import { useContext, useEffect, useState } from 'react';
import DispatchContext from '../../contexts/DispatchContext';
import ApiCall from '../../helpers/ApiCall';
import TR from '../../components/country/TR';
import BR from '../../components/country/BR';
import { Box } from "@flixbus/honeycomb-react";
import CL from '../../components/country/CL';
import PaxListMessage from './PaxListMessage';
import IN from '../../components/country/IN';

const PaxWidget = ({ rideUuid }) => {
  const appDispatch = useContext(DispatchContext);

  const { rideId } = useParams();
  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  const [result, setResult] = useState();

  useEffect(() => {
    const effectData = async () => {
      if (accounts?.length > 0)
        findCountry();
    }
    effectData();
  }, [accounts[0]?.name]);




  const findCountry = async () => {
    try {
      const response = await ApiCall("GetCountryOfRide?rideId=" + (rideUuid ? rideUuid : rideId) + "&timezone=" + Intl.DateTimeFormat().resolvedOptions().timeZone, "Get", null);
      setResult(response.data);
    } catch (error) {
      console.log(error);
      await appDispatch({ type: "showNotification", notification: { message: error, appearance: "danger" } });
    }

  }



  // const triggerManuel = () => appDispatch({ type: "showNotification", notification: { message: Math.random(0, 1), appearance: "success" } });

  const findComponent = () => {
    if (accounts?.length > 0) {
      if (result?.data?.country) {
        switch (result?.data?.country[0]) {
          case 'TR':
            return <TR countryData={result.data} rideId={rideUuid ? rideUuid : rideId} />;
          case 'BR':
            return <BR countryData={result.data} rideId={rideUuid ? rideUuid : rideId} />;
          case 'CL':
            return <CL countryData={result.data} rideId={rideUuid ? rideUuid : rideId} />;
          case 'IN':
            return <IN countryData={result.data} rideId={rideUuid ? rideUuid : rideId} />;
          default: {
            if (result?.data?.country?.includes("TR"))
              return <TR countryData={result.data} rideId={rideUuid ? rideUuid : rideId} />;
            else
              return <PaxListMessage />;
          }
        }
      }
      else if (result && result?.data?.country === null)
        return <div>Country couldn't be found.</div>
      else if (result && result?.success === false)
        if (result?.message === "Warning")
          return <PaxListMessage />
        else
          return <div>
            {result?.message}
          </div>
      else {
        return <div>Loading...</div>
      }
    } else {
      return (
        <div>
          if you successfully logged in widget will refresh.
        </div>
      )
    }
  }

  return (
    <Box extraClasses="flix-space-2-top flix-space-2-left max500">
      {findComponent()}
    </Box>
  )

}

export default PaxWidget;