import { useMsal } from '@azure/msal-react';
import {
    Header,
    HeaderBrand,
    HeaderSubNavigation,
    HeaderUserWidget,
    HeaderWidgets,
    NavItem,
} from '@flixbus/honeycomb-react';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AppHeader.module.scss';
import { loginRequest } from '../../configs/authConfig';
import LanguageSwitcher from '../languageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const handleLogOut = (instance) => {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

const handleLogin = (instance) => {
    localStorage.clear();
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

const AppHeader = () => {
    const { t } = useTranslation();
    const { accounts, instance } = useMsal();
    const account = accounts[0];
    return (
        <Header fixed>
            <HeaderBrand
                key="header-brand"
                alt="Flixbus"
                href="/"
                src={"https://honeycomb.flixbus.com/dist/7.2.13/img/logos/svg/flixbus-white.svg"}
                RouterLink={NavLink}
            />
            <HeaderWidgets >
                <div className="widget-wrapper" style={{ display: "flex" }}>
                    <div style={{ whiteSpace: "nowrap", display: "flex", marginRight: '1rem' }}>
                        <LanguageSwitcher />
                    </div>
                    <div className="header-widget-item">

                        {account ?
                            <HeaderUserWidget
                                data-dd-privacy="mask"
                                subMenu={
                                    <HeaderSubNavigation id="pikachu-sub-menu" data-dd-privacy="allow">
                                        <NavItem href="#" onClick={() => handleLogOut(instance)}>{t('buttons.logout')}</NavItem>
                                    </HeaderSubNavigation>
                                }
                            >
                                {account.name || ''}
                            </HeaderUserWidget>
                            :
                            <HeaderUserWidget onClick={() => handleLogin(instance)}>
                                Login
                            </HeaderUserWidget>

                        }
                    </div>
                </div >



            </HeaderWidgets>
        </Header>
    );
};

export default memo(AppHeader);
